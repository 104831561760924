var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.0.1-1c40ff5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const { version } = require("./package.json")

const COMMIT_HASH = process.env.BITBUCKET_COMMIT || "local"
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || "production"
const release =
  process.env.NEXT_PUBLIC_APP_VERSION ||
  [version, COMMIT_HASH.slice(0, 7)].join("-")

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://18e8fd19455f4b8da97b61a42206c954@o38280.ingest.sentry.io/6369680",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  environment: SENTRY_ENVIRONMENT,
  normalizeDepth: 5,
  ignoreErrors: [
    "ChunkLoadError",
    "NEXT_REDIRECT",
    "NEXT_NOT_FOUND",
    "Dynamic server usage: cookies",
  ],
  release,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
